import { Flex, HStack, Heading, Text, Box, Img } from '@chakra-ui/react'
import React from 'react'
import logo3 from './assets/sdlc.png'
const Body = () => {
  return (
    <Flex 
      w='100%' 
      bg='ghost' 
      color='black' 
      wrap='wrap'  
      direction={'column'} 
      p={4} 
      justifyContent={'center'}
      alignItems={'center'}
    >
      {/* <Heading 
        fontSize={{ base: '20px', md: '25px' }} 
        color={'blue'} 
        textAlign={'center'} 
        mb={4}
      >
        Welcome to IFITIPL Group
      </Heading> */}
      <Flex 
        direction={{ base: 'column', md: 'row' }} 
        w='100%' 
        maxW='1200px'
        alignItems={'center'}
      >
        <Box 
          w={{ base: '100%', md: '50%' }} 
          boxShadow={'sm'} 
          p={6} 
          fontWeight={'500'} 
          fontSize={{ base: '15px', md: '16px' }}
          mb={{ base: 4, md: 0 }}
          color={'gray'}
        >
          <Text mb={4}>
          We are IMMORTAL FUTURE INFO TECH, established in May 2024. Since our inception, we have been delivering customized billing software, website design and development, and mobile applications to leading Indian and multinational organizations, both domestically and internationally. Additionally, we offer comprehensive IT manpower solutions, ensuring our clients receive the best expertise and support.
          </Text>
          <Text mb={4}>
          IMMORTAL FUTURE INFO TECH has successfully completed numerous projects for small-scale industries, including website design, supermarket applications, mobile applications, medical and educational software, and payroll and inventory management systems. Our services span across India, the Middle East, and East Africa.
          </Text>
          <Text mb={4}>
          IMMORTAL FUTURE INFO TECH specializes in developing fully customized software solutions tailored to the specific requirements of our clients. Our expertise spans across diverse industries, delivering robust and tailored software solutions that meet the unique needs of organizations.
          </Text>
          <Text>
          Furthermore, we prioritize timely and responsive support, ensuring our clients receive exceptional service whenever they need it.
          </Text>
        </Box>
        <Box 
          w={{ base: '100%', md: '50%' }} 
          p={2} 
          boxShadow={'sm'} 
          display={'flex'} 
          justifyContent={'center'}
        >
          <Img  
            src={logo3} 
            w={{ base: '100%', md: 400 }} 
            h={{ base: 'auto', md: 300 }} 
            objectFit={'cover'}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Body
