// import { Flex, Heading,    Text, Box } from '@chakra-ui/react'
// // import { Flex, Heading,   Img Text, Box } from '@chakra-ui/react'
// import React from 'react'
// // import logo from './assets/logo.png'

// const Footer = () => {
//   return (
//     <Flex
//       w='100%'
//       bg='#e5e4e2'
//       color='black'
//       wrap='wrap'
//       direction={{ base: 'column', md: 'row' }}
//       p={8}
//       justifyContent={'center'}
//       alignItems={'center'}
//     //   gap={1}
//     >
//       <Box w={{ base: '100%', md: '25%' }} textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
//         <Heading mb={2}>
//           {/* <Img src={logo} w={{ base: 'auto', md: 200 }} h={{ base: 'auto', md: 100 }} /> */}
//         </Heading>
//         <Text fontSize={'14'}>
//           IFITIPL has been successfully done many projects small scale industries like website designing, super market, Android application, medical and school, college and payroll management in India, middle East and east Africa.
//         </Text>
//       </Box>
//       <Box w={{ base: '100%', md: '25%' }}  h={190} textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
//         <Heading mb={2} fontSize={'20'} color={'blue.500'}>Company</Heading>
//         <Text fontSize={'14'}>About US</Text>
//         <Text fontSize={'14'}>Contact US</Text>
//         <Text fontSize={'14'}>Portfolio</Text>
//       </Box> 
       
//       <Box w={{ base: '100%', md: '25%' }} textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
//         <Heading mb={2} fontSize={'20'} color={'blue.500'}>Our Services</Heading>
//         <Text fontSize={'15'}>Machine learning and Deep learning </Text>
//         <Text fontSize={'15'}></Text>
//         <Text fontSize={'15'}>Payroll and Inventory Management</Text>
//         <Text fontSize={'15'}>Mobile Applications</Text>
//         <Text fontSize={'15'}>Web Development</Text>
//         <Text fontSize={'15'}>Web Designing</Text>
//       </Box>
//       <Box w={{ base: '100%', md: '25%' }} textAlign={{ base: 'center', md: 'left' }}>
//         <Heading mb={2} fontSize={'20'} color={'blue.500'}>India Address</Heading>
//         <Text >
//              26 Gandhi Nagar 2<br/>
//           and across, <br/>
//           College road,<br/>
//           Musiri-621211 <br/>
        
//         </Text>
//       </Box>


//       <Box w={{ base: '100%', md: '25%' }} textAlign={{ base: 'center', md: 'left'    }}>
//         <Heading mb={2} fontSize={'20'} color={'blue.500'}>Discuss About Your Project
// </Heading>
//         <Text >
//         Shankar Murugesan<br/>
//           +91 9159855985 <br/>
//           vmshankar86@gmail.com<br/>  
//         </Text>
//       </Box>
     
//     </Flex>
//   )
// }

// export default Footer

 


import React from 'react';
import { Flex, Heading, Text, Box, Grid } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Flex
      w='100%'
      bg='#e5e4e2'
      color='black'
      wrap='wrap'
      direction={{ base: 'column', md: 'row' }}
      p={8}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} // Responsive grid columns
        gap={6} // Gap between grid items
        w='100%'
        maxW='1200px' // Max width of the grid
      >
        {/* First Grid Item (Company Info) */}
        <Box textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
          <Heading mb={2} fontSize={'20'} color={'black.500'}>
            Company
          </Heading>
          <Text fontSize={'14'}>About US</Text>
          <Text fontSize={'14'}>Contact US</Text>
          <Text fontSize={'14'}>Portfolio</Text>
        </Box>

        {/* Second Grid Item (Our Services) */}
        <Box textAlign={{ base: 'center', md: 'left' }} mb={{ base: 4, md: 0 }}>
          <Heading mb={2} fontSize={'20'} color={'black.500'}>
            Our Services
          </Heading>
          <Text fontSize={'15'}>Data Science Consulting</Text>
          <Text fontSize={'15'}>Fullstack Development(Desktop and Mobile Application)</Text>
          <Text fontSize={'15'}>Salesforce Implementation</Text>
          <Text fontSize={'15'}>Industrial Internet of Things </Text> 
        </Box>

        {/* Third Grid Item (India Address) */}
        <Box textAlign={{ base: 'center', md: 'left' }}>
          <Heading mb={2} fontSize={'20'} color={'black.500'}>
            India Address
          </Heading>
          <Text>
            26 Gandhi Nagar 2<br />
            and across, <br />
            College road,<br />
            Musiri-621211 <br />
          </Text>
        </Box>

        {/* Fourth Grid Item (Discuss About Your Project) */}
        <Box textAlign={{ base: 'center', md: 'left' }}>
          <Heading mb={2} fontSize={'20'} color={'black.500'}>
            Discuss About Your Project
          </Heading>
          <Text>
            Shankar Murugesan<br />
            +91 9159855985 <br /> 
          </Text>
        </Box>
      </Grid>
    </Flex>
  );
};

export default Footer;
