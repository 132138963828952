import * as React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
// import Header from './pages/Header';
import Navbar from './pages/Navbar';
import Body from './pages/Body';
import Service from './pages/Service';
import Footer from './pages/Footer';
// import OverlayComponent from './pages/ImageLayout';
import Carousel from './pages/Carousel';
import Main from './Main';

function App() {
  const contactRef = React.useRef(null);
  const servicesRef = React.useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToServices = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ChakraProvider>
      {/* <Header />  */}
      <Navbar scrollToContact={scrollToContact} scrollToServices={scrollToServices} />
      <Carousel />
      <Body />
      <Box ref={servicesRef}>
        <Service />
      
      </Box>
      {/* <OverlayComponent /> */}
      <Box ref={contactRef}>
        <Footer />
      </Box>
      <Main />
    </ChakraProvider>
  );
}

export default App;
