import React from 'react';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
// import Home from './Home';
import OverlayComponent from './pages/ImageLayout';

function Main() {
    return (
        
        // <Router>

            <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/contact" element={<OverlayComponent />} />
            </Routes>
    //  </Router>
    );
}

export default Main;
