import { Box, Flex, Heading, Text } from '@chakra-ui/react'
// import { Box, Flex, Heading, Text,img } from '@chakra-ui/react'
import React from 'react'

const OverlayComponent = () => {
  return (
    <Box
      position="relative"
      w="100%"
      h="600px" // Adjust height as needed
      overflow="hidden"
    >
      {/* <Img
        src="https://stackpos.in/assets/images/banner-(2).jpg"
        w="100%"
        h="100%"
        objectFit="cover"
        alt="Background Image"
      /> */}
      <Box
        position="absolute"
        top="0%"
        left="50%"
        transform="translate(-50%, 0)"
        bg="rgba(0, 0, 0, 0.5)"
        color="white"
        p={9}
        h={'100vh'}
        borderRadius="md"
        textAlign="left"
        w={{ base: '100%', md: '100%' }}
      >
        <Heading fontSize={{ base: '20px', md: '28px' }} mb={4} color={'blue.400'}>IFITPL GROUP</Heading>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={4}
        >
          <Box w={{ base: '100%', md: '33%' }} mb={{ base: 4, md: 0 }}>
            <Heading fontSize="22px" mb={2} color={'blue.400'}>India Address:</Heading>
            <Text mb={5} fontWeight={'600'}>
              26 Gandhi Nagar<br />
              2 and cross <br />
              College road<br />
              {/* Near DDCC Bank<br /> */}
              {/* Krishnagiri, Hosur<br /> */}
              Musiri, India-621211
            </Text>
            {/*<Heading fontSize="22px" mb={2} color={'blue.400'}>Kenya Address:</Heading>
            <Text fontWeight={'600'}>
            WOODVALE GROVE<br />
            MPAKA HOUSE<br />
            WESTLANDS<br />
            NAIROBI,KENYA<br />
            P.O BOX 66602<br />
            TELEPHONE: +254715175279
            </Text>*/}
          </Box>
          <Box w={{ base: '100%', md: '33%' }} mb={{ base: 4, md: 0 }}>
            <Heading fontSize="22px" mb={2} color={'blue.400'}>Discuss About  Your Project</Heading>
            <Text fontSize={'20'} fontWeight={'600'}>
               SHANKAR MURUGESAN</Text><br />
            <Text fontSize={'20'} fontWeight={'600'}>
               +91 9159855985<br />
              {/* +91 99523 33641 */}
            </Text>
          </Box>
          <Box w={{ base: '100%', md: '33%' }}>
            <Heading fontSize="22px" mb={2} color={'blue.400'}>Email</Heading>
            <Text fontSize={'20'} fontWeight={'600'}>
            {/* info@IFITPL.in<br /> */}
            vmshankar86@gmail.com
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default OverlayComponent;
