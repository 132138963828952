 
import React, { useEffect } from 'react';
import { Box, Flex, Img, Text, Grid, GridItem } from '@chakra-ui/react';
import { animateScroll as scroll } from 'react-scroll';
import { motion } from 'framer-motion';
import service1 from './assets/mlanddl.jpeg';
import service2 from './assets/saas.jpg';
import service3 from './assets/webdev.jpg';
import service4 from './assets/iot.png';

const services = [
  {
    src: service1,
    description: 'Data Science Consulting',
    about: "IMMORTAL FUTURE INFO TECH specializes in ML and DL technologies, offering solutions that analyze extensive datasets, uncover patterns, and provide actionable insights. Their intelligent systems, powered by advanced algorithms and neural networks, continuously learn and adapt, ensuring high accuracy. Whether it’s predictive analytics, task automation, or data insights, their expertise empowers businesses to stay competitive."
  },
  {
    src: service3,
    description: 'Fullstack Development (Desktop and Mobile application)',
    about: "IMMORTAL FUTURE INFO TECH specializes in developing desktop web applications that seamlessly blend functionality, performance, and user experience. Their team creates powerful, scalable web applications tailored to specific business needs, ensuring robustness, security, and peak performance."
  },
  {
    src: service2,
    description: 'Salesforce Implementation',
    about: "IMMORTAL FUTURE INFO TECH specializes in Salesforce implementation services. Their dedicated team customizes the deployment process, from initial consultation to seamless integration and comprehensive training. By adhering to industry best practices, they empower organizations with enhanced customer relationship management, improved sales performance, and actionable insights."
  },
  {
    src: service4,
    description: 'Industrial Internet of Things (IIOT)',
    about: "IMMORTAL FUTURE INFO TECH specializes in Industrial Internet of Things (IIOT) solutions. Their offerings enable seamless connectivity and data exchange between devices, systems, and platforms, enhancing operational efficiency and informed decision-making. By leveraging advanced analytics, machine learning, and real-time monitoring, their IIOT solutions optimize processes and boost productivity across various industrial sectors."
  },
];

const Service = () => {
  useEffect(() => {
    // Scrolls to the top of the Service section with smooth animation on component mount
    scroll.scrollToTop({
      duration: 500, // Animation duration in milliseconds
      smooth: 'easeInOutQuart' // Easing function for smooth animation
    });
  }, []);

  return (
    <Flex
      w='100%'
      bg='ghost'
      color='black'
      wrap='wrap'
      direction='column'
      p={4}
      justifyContent='center'
      alignItems='center'
      id='services' // ID for smooth scroll anchor
    >
        
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
        gap={6}
        w='100%'
        maxW='1200px'
      >
        {services.map((service, index) => (
          <GridItem key={index} textAlign='left'>
            <motion.div
              initial={{ opacity: 0, y: 20 }}  
              animate={{ opacity: 1, y: 0 }} 
              transition={{ duration: 0.6, delay: index * 0.2 }}  
              style={{ width: '100%' }}  
            >
              <Box boxShadow='sm' p={6} borderRadius='md' borderWidth='1px' className='box-animation' bg='white'>
                <Img style={{ width: "550px", height: "270px" }} src={service.src} alt={`Service ${index + 1}`} mb={4} />
                <Text fontWeight='700' fontSize={{ base: '20px', md: '23px' }} color='black.400' mb={2}>{service.description}</Text>
                <Text fontWeight='400' fontSize={{ base: '15px', md: '16px' }} color='gray.600' lineHeight='1.6'>{service.about}</Text>
              </Box>
            </motion.div>
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );
};

export default Service;
