import React from 'react';
import { 
  ChakraProvider, 
  Box, 
  Flex, 
  IconButton, 
  Img, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  Button, 
  Drawer, 
  DrawerBody, 
  DrawerOverlay, 
  DrawerContent, 
  DrawerCloseButton, 
  useDisclosure, 
  Stack 
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from './assets/logo2.png';
import { Link } from 'react-router-dom';



function Navbar({scrollToContact , scrollToServices}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const Handler = () => {
    onClose(); // Assuming onClose is a function to close something
    
    setTimeout(() => {
      scrollToContact(); // Assuming scrollToContact is a function to scroll to a specific contact
    }, 100); // 1000 milliseconds = 1 second
  };
  
  return (
    <ChakraProvider>
      <Box bg={'#f8f8ff'} px={4}>
        <Flex h={'100%'} alignItems="center" justifyContent="space-between">
          <Box ml={10}>
            <Img src={logo} w={{ base: 'auto', md: 150 }} h={{ base: '20', md: 100 }} />
           
          </Box>

          <Flex display={{ base: 'none', md: 'flex' }} alignItems="center">
            <Link to="#">
              <Box color="black" as='button' p={7} fontWeight={'600'} _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }}>
                HOME
              </Box>
            </Link>
            
            <Box color="black" as="button" p={7} fontWeight="600" _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }} onClick={scrollToServices}>
              SERVICES
            </Box>

            <Link to="#">
              <Box color="black" as='button' p={7} fontWeight={'600'} _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }}>
                PORTFOLIO
              </Box>
            </Link>
            <Link to="#">
              <Box color="black" as='button' p={7} fontWeight={'600'} _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }}>
                ABOUT US
              </Box>
            </Link>
            <Box color="black" as="button" p={7} fontWeight="600" _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }} onClick={scrollToContact}>
              CONTACT US
            </Box>
          </Flex>
          <IconButton
            size="md"
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Open Menu"
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
        </Flex>





{/*SHOR DEVICES========================*/}
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody>
                <Stack as="nav" spacing={4}>
                  {/*<Link to="#" onClick={onClose}>*/}
                    <Box color="black" as='button' fontWeight={'900'} _hover={{ color: 'white', bg: 'blue.900', fontWeight: 900 }}>
                      IFIT
                    </Box>
                  {/*</Link>*/}

                  <Box color="black" as="button" fontWeight="600" _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }} onClick={() => { onClose(); scrollToServices(); }}>
                    Services
                  </Box>

                  {/*<Link to="#" onClick={onClose}>*/}
                    <Box color="black" as='button' fontWeight={'600'} _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }} 
                    onClick={() => { onClose(); scrollToServices(); }} 
                    >
                     Portfolio
                    </Box>
                  {/*</Link>*/}
                  {/*<Link to="#" onClick={onClose}>*/}
                    <Box color="black" as='button' fontWeight={'600'} _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }}    onClick={() => {  scrollToServices();onClose()}}>
                      About Us
                    </Box>
                  {/*</Link>*/}
                  <Box color="black" as='button' fontWeight={'600'} _hover={{ color: 'white', bg: 'blue.400', fontWeight: 600 }} 
                    
                    onClick={Handler}
                    
                    >
                    Contact Us
                  </Box>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
    </ChakraProvider>
  );
}

export default Navbar;
