 
import React from 'react';
import { Box } from '@chakra-ui/react';
import logo1 from './assets/homeset.mp4';

const Carousel = () => {
  return (
    <Box position="relative" w="100%" mx="auto">
      <video
        src={logo1}
        autoPlay
        muted
        loop
        width="100%"
        style={{
          height: 'calc(50vh)',  
          objectFit: 'cover'
        }}
      />
    </Box>
  );
};

export default Carousel;

 